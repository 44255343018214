<template>
  <div class="related-resources w-full">
    <div class="related-resources-wrapper">
      <Card
        display-type="carousel"
        :resources="resources"
        :title="title"
        :hideArrowButtons="hideArrowButtons">
      </Card>
    </div>
  </div>
</template>

<script>
import Card from "./Card.vue";

export default {
  components: {
    Card,
  },
  props: {
    resources: {
      type: Array,
      required: true,
      default: () => [],
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    hideArrowButtons: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
};
</script>

<style lang="scss">
.related-resources {
  &-wrapper {
    > * {
      > *:not(.NurseResourceCard-views) {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 0.5rem;
        margin-top: 2rem;

        @media screen and (max-width: 768px) {
          & {
            grid-template-columns: 1fr
          }
        }

        > * { 
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
