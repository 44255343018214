<template>
  <div class="partner-banner w-full">
    <div
      class="partner-banner-bg block">
      <img :src="details.image" class="w-full">
      <img :src="details.icon" class="partner-banner-bg-icon relative"/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    details: {
      type: Object,
      required: true,
      default: () => ({
        image: '',
        icon: ''
      }),
    },
  },
}
</script>

<style lang="scss">
.partner-banner {
  &-bg {
    border-radius: 1.8rem;

    &-icon {
      height: 100px !important;
      width: 100px !important;
      margin-top: -175px;
      left: 30px;
      background: #FFFFFF;
      border-radius: 1.5rem;
    }

    @media (min-width: 800px) {
      &-icon {
        height: 150px !important;
        width: 150px !important;
      }
    }
  }
}
</style>