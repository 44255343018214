<template>
  <div class="care-help w-full p-4 flex sm:flex-row flex-col sm:items-center">
    <div class="sm:mb-0 mb-5">
      <div class="care-help-img-cont flex items-center justify-center">
        <img class="img-fluid" src="/care-help.png"/>
      </div>
    </div>
    <div class="sm:ml-4">
      <h5 class="text-xl lg:text-1xl font-semibold care-help-message">We are always here to help you!</h5>
      <h5 class="text-base font-semibold text-gray-600 mt-2">Reach out to your account manager or write to our team:</h5>
      <h5 class="text-base font-semibold mt-2 care-help-email">hello@freshclinics.com</h5>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style lang="scss">
.care-help {
  background: #EBF7F4;
  border-radius: 8px;

  &-img-cont {
    border-radius: 50%;
    height: 50px;
    width: 50px;
    background: #ffff;

    img {
      width: 20px;
      height: 20px;
    }
  }

  &-message,
  &-email {
    color: #0E4333;
  }
}
</style>