<template>
  <div class="partner w-full">
    <!-- navigation -->
    <vs-breadcrumb
      class="block mb-2 resources-breadcrumb"
      :items="populateBreadcrumbs"
    ></vs-breadcrumb>

    <div class="partner-wrapper flex flex-col items-center space-y-6">
      <!-- banner -->
      <partner-banner :details="currentPartner"></partner-banner>

      <!-- details -->
      <partner-details :details="currentPartner"></partner-details>

      <!-- divider -->
      <vs-divider  />

      <!-- resources -->
      <related-resources
        :resources="resources"
        :title="title"
        :hideArrowButtons="true"/>

      <!-- divider -->
      <vs-divider  />

      <!-- care help -->
      <care-help  />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import RelatedResources from "./resources/RelatedResources.vue";
import CareHelp from "./resources/CareHelp.vue";
import PartnerBanner from "./resources/PartnerBanner.vue";
import PartnerDetails from "./resources/PartnerDetails.vue";

export default {
  components: {
    RelatedResources,
    CareHelp,
    PartnerBanner,
    PartnerDetails
  },
  data() {
    return {
      pagination: {
        limit: 100,
        page: 1
      },
    }
  },
  methods: {
    ...mapActions("resourceHub", ["searchResources"]),
    ...mapActions("admin", ["getSettingsByKey"]),
    ...mapMutations("resourceHub", ["MUTATE_RESOURCE_JSON_SETTING"]),

    async fetchTopicDetails() {
      try {
        const { data } = await this.getSettingsByKey("resourceHubSetting")
        
        let formattedValue = {
          main_banner: {
            header: "",
            subHeader: "",
            image: ""
          },
          first_section: {
            header: "",
            tag: ""
          },
        }

        if (Object.keys(JSON.parse(data.data.value)).length) {
          formattedValue = JSON.parse(data.data.value)
        }

        this.MUTATE_RESOURCE_JSON_SETTING(formattedValue)
      } catch (error) {
        console.log(`Error fetching topic details: ${error}`)
      }
    },
    async fetchResources() {
      try {
        this.$vs.loading();
        const currentPartner = this.currentPartner

        // fetch resources
        await this.searchResources({
          ...this.pagination,
          brand: currentPartner ? currentPartner.value : ''
        })
      } catch (error) {
        console.log(`Error fetching resources: ${error}`)
      } finally {
        this.$vs.loading.close()
      }
    }
  },
  computed: {
    populateBreadcrumbs () {
      const { topic } = this.$route.params
      const partner = this.currentPartner
      const userType = this.$route.path.split('/')[1]

      return [
        {
          title: 'Resources',
          url: `/${userType}/resources`
        },
        {
          title: `${topic}`,
          url: `/${userType}/resources/${topic}`,
        },
        {
          title: `${partner ? partner.label : ''}`,
          active: true,
        },
      ]
    },
    currentPartner () {
      const { popular_categories } = this.$store.state.resourceHub.settings
      const { topic, brand } = this.$route.params

      const currentTopic = (popular_categories || []).find(i => i.value === topic);

      if (currentTopic) return currentTopic.page.partners.find(i => i.value === brand)
      return undefined
    },
    resources() {
      return this.$store.state.resourceHub.resources.searchResults.results;
    },
    title() {
      const currentPartner = this.currentPartner

      return `${currentPartner ? currentPartner.label : '' } resources`
    }
  },
  watch: {
    '$store.state.resourceHub.settings': function (val) {
      if (val.popular_categories && val.popular_categories.length) {
        this.fetchResources()
      }
    }
  },
  created() {
    this.fetchTopicDetails();
  }
}
</script>

<style lang="scss">
.partner {
  .resources-breadcrumb {
    .vs-breadcrumb--link {
      color: rgba(14, 67, 51, 1);
    }
    .vs-active {
      .vs-breadcrumb--text {
        color: rgba(37, 48, 44, 0.65);
      }
    }
  }

  .vs {
    &-divider {
      margin: 0;
    }
  }
}
</style>